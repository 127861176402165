import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MaterialTable from 'material-table'

import agent from '../../agent'

import { UPDATE_SCORE } from '../../constants/actionTypes'

const mapDispatchToProps = (dispatch) => ({
  onUpdate: (score) =>
    dispatch({
      type: UPDATE_SCORE,
      payload: agent.Scores.update(score),
      snackbar: { message: 'Score updated', variant: 'success' },
    }),
})

const TaskDataTable = ({ onUpdate, scores, task }) => {
  const [data, setData] = useState([])

  const mappedData = scores.map((score) => ({
    firstName: score.student.firstName,
    lastName: score.student.lastName,
    value: score.value,
    percent: Math.round((score.value / (task.totalPoints || 10)) * 100),
    id: score._id,
  }))

  const [columns] = useState([
    { title: 'Last Name', field: 'lastName', editable: 'never' },
    { title: 'First Name', field: 'firstName', editable: 'never' },
    {
      title: 'Score',
      field: 'value',
      render: (rowData, renderType) =>
        `${renderType === 'row' ? rowData.value : rowData} / ${
          task.totalPoints
        }`,
    },
    {
      title: 'Percent',
      field: 'percent',
      editable: 'never',
      render: (rowData, renderType) =>
        `${renderType === 'row' ? rowData.percent : rowData}%`,
    },
  ])

  useEffect(() => {
    setData(mappedData)
  }, [scores])

  return (
    <MaterialTable
      title='Student Scores'
      columns={columns}
      data={data}
      localization={{
        body: { emptyDataSourceMessage: 'No scores found for this assignment' },
      }}
      options={{ actionsColumnIndex: -1, grouping: true, paging: false }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data]
            const index = oldData.tableData.id
            dataUpdate[index] = { ...newData }
            setData([...dataUpdate])
            onUpdate(newData)
            resolve()
          }),
      }}
    />
  )
}

export default connect(() => ({}), mapDispatchToProps)(TaskDataTable)
