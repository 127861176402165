import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import ConfirmationDialog from '../ConfirmationDialog'
import ListErrors from '../ListErrors'
import PrimeBanner from './PrimeBanner'
import PrimeConfirmation from './PrimeConfirmation'
import PrimeSignup from './PrimeSignup'
import agent from '../../agent'

import {
  PRIME_PURCHASE_DIALOG_UNLOADED,
  SUBMIT_PRIME_PURCHASE,
} from '../../constants/actionTypes'

const useStyles = makeStyles({
  paper: {
    overflow: 'hidden',
  },
})

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  errors: state.purchases.errors,
  inProgress: state.purchases.inProgress,
  prime: state.common.settings.prime,
  scores: state.scores,
  settings: state.common.settings,
  songRemovalRequestEnabled: state.common.settings.songRemovalRequestEnabled,
})

const mapDispatchToProps = (dispatch) => ({
  onExit: () => dispatch({ type: PRIME_PURCHASE_DIALOG_UNLOADED }),
  onSubmit: (transaction) =>
    dispatch({
      type: SUBMIT_PRIME_PURCHASE,
      payload: agent.Transactions.add(transaction),
      snackbar: { message: 'Now Enrolled in Prime', variant: 'success' },
    }),
})

const PrimeBannerDialog = ({
  currentUser,
  errors,
  inProgress,
  onExit,
  onSubmit,
  prime,
  scores,
  settings,
  songRemovalRequestEnabled,
  trigger,
}) => {
  const [confirm, setConfirm] = useState(false)
  const [open, setOpen] = useState(false)
  const [testPrime, setTestPrime] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setTestPrime(false)
  }

  const handleConfirm = () => {
    setConfirm(true)
  }

  const handleCancelConfirm = () => {
    setConfirm(false)
  }

  const handleSubmit = () => {
    setConfirm(false)
    if (currentUser.role === 'student') {
      onSubmit({
        amount: prime.purchaseAmount,
        description: 'Chem Cash Prime Membership',
        type: 'purchase',
        student: { _id: currentUser._id },
      })
    } else {
      setTestPrime(true)
    }
  }

  const enabled = {
    any:
      prime.darkModeEnabled ||
      prime.songRequestPurchaseDiscount > 0 ||
      prime.storePurchaseDiscount > 0 ||
      prime.sameDayDeliveryEnabled,
    darkMode: prime.darkModeEnabled,
    discounts: {
      hallpass: prime.hallpassDiscount > 0,
      songs: prime.songRequestPurchaseDiscount > 0,
      store: prime.storePurchaseDiscount > 0,
    },
    jukebox:
      prime.jukeboxEnabled &&
      settings.user.permissions?.jukebox &&
      settings.enableBetaFeatures &&
      settings?.jukebox?.enabled &&
      settings.spotifyTokens,
    sameDay: prime.sameDayDeliveryEnabled,
    skipSongs: settings?.jukebox?.skipSongs?.enabled,
    songRemoval: prime.songRemovalEnabled && songRemovalRequestEnabled,
  }

  const showBanner = prime.enabled && !currentUser.hasPrime
  const showButton = prime.enabled && currentUser.hasPrime

  return (
    <>
      {showBanner && trigger === 'banner' && (
        <PrimeBanner onClick={handleClickOpen} />
      )}
      {showButton && trigger === 'button' && (
        <Button onClick={handleClickOpen} size='small'>
          View Prime Benefits
        </Button>
      )}
      <Dialog
        fullWidth
        classes={{ paper: classes.paper }} // needed due to overflow/scroll when showing <PrimeConfirmation /> (due to confetti)
        fullScreen={isMobile}
        maxWidth='lg'
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onExit={onExit}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <DialogContent
          style={{
            overflowX: 'hidden',
            overflowY:
              (currentUser.hasPrime || testPrime) && !isMobile ? 'hidden' : '', // needed due to slight overflow/scroll when showing <PrimeConfirmation />
            padding: 0,
          }}
        >
          <ListErrors errors={errors} />
          {currentUser.hasPrime || testPrime ? (
            <PrimeConfirmation
              celebrate={trigger === 'banner'}
              enabled={enabled}
              prime={prime}
            />
          ) : (
            <PrimeSignup
              balance={scores.total?.balance}
              enabled={enabled}
              onSubmit={handleConfirm}
              prime={prime}
            />
          )}
        </DialogContent>
        <DialogActions>
          {!currentUser.hasPrime && !testPrime && (
            <Button
              color='primary'
              disabled={
                inProgress || scores.total?.balance < prime.purchaseAmount
              }
              onClick={handleConfirm}
              type='submit'
              variant='contained'
            >
              {inProgress ? 'Purchasing...' : 'Purchase Prime'}
            </Button>
          )}
          <Button
            disabled={inProgress}
            onClick={handleClose}
            variant='contained'
          >
            {currentUser.hasPrime || testPrime ? 'Close' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        isOpen={confirm}
        label='Purchase'
        message={`Your account will be charged ${prime.purchaseAmount} pts and you will have instant access to Prime benefits`}
        onCancel={handleCancelConfirm}
        onSubmit={handleSubmit}
        title='Purchase a Prime membership?'
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimeBannerDialog)
