import {
  ADD_BONUS,
  ADD_COURSE,
  ADD_FINE,
  ADD_PASS,
  ADD_PRODUCT,
  ADD_STUDENT,
  ADD_TEACHER,
  ADD_TO_QUEUE,
  ADD_TRANSACTION,
  ADD_TRANSACTIONS,
  CREATE_PLAYLIST,
  DELETE_BONUS,
  DELETE_COURSE,
  DELETE_FINE,
  DELETE_PASS,
  DELETE_PRODUCT,
  DELETE_PURCHASE,
  DELETE_SONG_REQUEST,
  DELETE_STUDENT,
  DELETE_TASK,
  DELETE_TEACHER,
  DELETE_TRANSACTION,
  IMPORT_SCORES,
  IMPORT_STUDENTS,
  JUKEBOX_REQUEST_SUBMIT,
  MOVE_STUDENT,
  REMOVE_PLAYLIST,
  RESET_STUDENT_PASSWORD,
  SETTINGS_SAVED,
  SKIP_TRACK,
  SNACKBAR_CLOSED,
  STUDENT_DELETE_PURCHASE,
  SUBMIT_PURCHASE,
  SUBMIT_PRIME_PURCHASE,
  SUBMIT_SONG_REQUEST,
  SUBMIT_SONG_REMOVAL_REQUEST,
  UPDATE_BONUS,
  UPDATE_COURSE,
  UPDATE_FINE,
  UPDATE_PASS,
  UPDATE_PRODUCT,
  UPDATE_SCORE,
  UPDATE_STUDENT,
  UPDATE_TASK,
  UPDATE_TEACHER,
  UPDATE_TRANSACTION,
  WHATS_NEW_FLAGS_RESET,
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_BONUS:
    case ADD_COURSE:
    case ADD_FINE:
    case ADD_PASS:
    case ADD_PRODUCT:
    case ADD_STUDENT:
    case ADD_TEACHER:
    case ADD_TO_QUEUE:
    case ADD_TRANSACTION:
    case ADD_TRANSACTIONS:
    case CREATE_PLAYLIST:
    case DELETE_BONUS:
    case DELETE_COURSE:
    case DELETE_FINE:
    case DELETE_PASS:
    case DELETE_PRODUCT:
    case DELETE_PURCHASE:
    case DELETE_SONG_REQUEST:
    case DELETE_STUDENT:
    case DELETE_TASK:
    case DELETE_TEACHER:
    case DELETE_TRANSACTION:
    case IMPORT_SCORES:
    case IMPORT_STUDENTS:
    case JUKEBOX_REQUEST_SUBMIT:
    case MOVE_STUDENT:
    case REMOVE_PLAYLIST:
    case RESET_STUDENT_PASSWORD:
    case SETTINGS_SAVED:
    case SKIP_TRACK:
    case STUDENT_DELETE_PURCHASE:
    case SUBMIT_PURCHASE:
    case SUBMIT_PRIME_PURCHASE:
    case SUBMIT_SONG_REQUEST:
    case SUBMIT_SONG_REMOVAL_REQUEST:
    case UPDATE_BONUS:
    case UPDATE_COURSE:
    case UPDATE_FINE:
    case UPDATE_PASS:
    case UPDATE_PRODUCT:
    case UPDATE_SCORE:
    case UPDATE_STUDENT:
    case UPDATE_TASK:
    case UPDATE_TEACHER:
    case UPDATE_TRANSACTION:
    case WHATS_NEW_FLAGS_RESET:
      return {
        ...state,
        snackbar: action.error
          ? null //{ message: 'An error occurred', variant: 'error' }
          : action.snackbar,
      }
    case SNACKBAR_CLOSED:
      return { ...state, snackbar: null }
    default:
      return state
  }
}
