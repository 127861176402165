import React, { useState } from 'react'
import {
  Button,
  Container,
  Dialog,
  Grid,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { Group } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import DialogAppBar from '../DialogAppBar'
import PageHeader from '../PageHeader'
import PageWrapper from '../PageWrapper'
import SortableGroups from './SortableGroups'
import { SlideUpTransition } from '../../transitions'

const MAX_GROUPS = 20
const MIN_GROUPS = 2

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(2),
  },
  controls: { alignItems: 'center', display: 'flex' },
}))

const GrouperDialog = ({ course }) => {
  const [open, setOpen] = useState(false)
  const [numGroups, setNumGroups] = useState(10)
  const [groups, setGroups] = useState([])

  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleGroupCountChange = (event) => {
    setGroups([])
    const value = Number(event.target.value)
    if (value < MIN_GROUPS) {
      setNumGroups(MIN_GROUPS)
    } else if (value > MAX_GROUPS) {
      setNumGroups(MAX_GROUPS)
    } else {
      setNumGroups(value)
    }
  }

  const shuffle = (students) => {
    const copy = [...students]
    const shuffled = []
    while (copy.length !== 0) {
      let randomIndex = Math.floor(Math.random() * copy.length)
      shuffled.push(copy[randomIndex])
      copy.splice(randomIndex, 1)
    }
    return shuffled
  }

  const group = (students) => {
    const shuffled = shuffle(students)
    const groups = [...new Array(numGroups)].map(() => [])
    shuffled.forEach((student, index) => {
      const groupNum = index % numGroups
      groups[groupNum].push(student)
    })
    return groups
  }

  const handleGenerateClick = () => {
    const activeStudents = course.students.filter((student) => !student.dropped)
    setGroups(group(activeStudents))
  }

  return (
    <>
      <ActionButton action='Random group generator' onClick={handleClickOpen}>
        <Group />
      </ActionButton>

      <Dialog
        fullScreen
        open={open}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        TransitionComponent={SlideUpTransition}
      >
        <DialogAppBar onClose={handleClose} title='Random Group Generator' />
        <PageWrapper>
          <Container>
            <PageHeader title={`Period ${course.period} | ${course.name}`} />
            <Grid container spacing={6}>
              <Grid item xs={12} className={classes.controls}>
                <TextField
                  id='groups'
                  label='Number of Groups'
                  onChange={handleGroupCountChange}
                  type='number'
                  value={numGroups}
                  variant='outlined'
                />
                <Button
                  className={classes.button}
                  color='primary'
                  onClick={handleGenerateClick}
                  variant='contained'
                >
                  Create Groups
                </Button>
              </Grid>
              <SortableGroups groups={groups} numGroups={numGroups} />
            </Grid>
          </Container>
        </PageWrapper>
      </Dialog>
    </>
  )
}

export default GrouperDialog
