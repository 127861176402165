export const APP_LOAD = 'APP_LOAD'
export const REDIRECT = 'REDIRECT'

export const ASYNC_START = 'ASYNC_START'
export const ASYNC_END = 'ASYNC_END'

export const LOGIN = 'LOGIN'
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED'
export const LOGOUT = 'LOGOUT'

export const REGISTER = 'REGISTER'
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED'

export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED'
export const SETTINGS_SAVED = 'SETTINGS_SAVED'

export const NAV_LOADED = 'NAV_LOADED'
export const NAV_UNLOADED = 'NAV_UNLOADED'
export const NAV_TAB_CHANGE = 'NAV_TAB_CHANGE'

export const ALERT_CLOSED = 'ALERT_CLOSED'
export const SNACKBAR_CLOSED = 'SNACKBAR_CLOSED'

export const DELETE_SONG_REQUEST = 'DELETE_SONG_REQUEST'
export const SONG_REQUESTS_DIALOG_UNLOADED = 'SONG_REQUESTS_DIALOG_UNLOADED'
export const SONGS_PAGE_UNLOADED = 'SONGS_PAGE_UNLOADED'
export const STUDENT_SONGS_PAGE_LOADED = 'STUDENT_SONGS_PAGE_LOADED'
export const SUBMIT_SONG_REQUEST = 'SUBMIT_SONG_REQUEST'
export const SUBMIT_SONG_REMOVAL_REQUEST = 'SUBMIT_SONG_REMOVAL_REQUEST'
export const TEACHER_SONGS_PAGE_LOADED = 'TEACHER_SONGS_PAGE_LOADED'
export const UPDATE_SONG_REQUEST = 'UPDATE_SONG_REQUEST'

export const ADD_COURSE = 'ADD_COURSE'
export const COURSE_DIALOG_UNLOADED = 'COURSE_DIALOG_UNLOADED'
export const COURSES_PAGE_LOADED = 'COURSES_PAGE_LOADED'
export const COURSES_PAGE_UNLOADED = 'COURSES_PAGE_UNLOADED'
export const DELETE_COURSE = 'DELETE_COURSE'
export const UPDATE_COURSE = 'UPDATE_COURSE'

export const ADD_STUDENT = 'ADD_STUDENT'
export const DELETE_STUDENT = 'DELETE_STUDENT'
export const IMPORT_STUDENTS = 'IMPORT_STUDENTS'
export const MOVE_STUDENT = 'MOVE_STUDENT'
export const RESET_STUDENT_PASSWORD = 'RESET_STUDENT_PASSWORD'
export const STUDENT_DIALOG_UNLOADED = 'STUDENT_DIALOG_UNLOADED'
export const UPDATE_STUDENT = 'UPDATE_STUDENT'

export const ADD_TEACHER = 'ADD_TEACHER'
export const DELETE_TEACHER = 'DELETE_TEACHER'
export const MANAGE_TEACHERS_PAGE_LOADED = 'MANAGE_TEACHERS_PAGE_LOADED'
export const MANAGE_TEACHERS_PAGE_UNLOADED = 'MANAGE_TEACHERS_PAGE_UNLOADED'
export const TEACHER_DIALOG_UNLOADED = 'TEACHER_DIALOG_UNLOADED'
export const UPDATE_TEACHER = 'UPDATE_TEACHER'

export const ADD_TO_QUEUE = 'ADD_TO_QUEUE'
export const CREATE_PLAYLIST = 'CREATE_PLAYLIST'
export const GET_PLAYLIST = 'GET_PLAYLIST'
export const GET_QUEUE = 'GET_QUEUE'
export const JUKEBOX_DIALOG_UNLOADED = 'JUKEBOX_DIALOG_UNLOADED'
export const JUKEBOX_REQUEST_SUBMIT = 'JUKEBOX_REQUEST_SUBMIT'
export const JUKEBOX_STUDENT_DIALOG_LOADED = 'JUKEBOX_STUDENT_DIALOG_LOADED'
export const JUKEBOX_TEACHER_DIALOG_LOADED = 'JUKEBOX_TEACHER_DIALOG_LOADED'
export const SKIP_TRACK = 'SKIP_TRACK'
export const SPOTIFY_REMOVE_AUTH = 'SPOTIFY_REMOVE_AUTH'
export const SPOTIFY_LINKER_LOADED = 'SPOTIFY_LINKER_LOADED'
export const SPOTIFY_LINKER_UNLOADED = 'SPOTIFY_LINKER_UNLOADED'
export const PLAYLIST_MANAGER_DIALOG_LOADED = 'PLAYLIST_MANAGER_DIALOG_LOADED'
export const PLAYLIST_MANAGER_DIALOG_UNLOADED =
  'PLAYLIST_MANAGER_DIALOG_UNLOADED'
export const REMOVE_PLAYLIST = 'REMOVE_PLAYLIST'

export const IMPORT_SCORES = 'IMPORT_SCORES'
export const IMPORT_SCORES_DIALOG_UNLOADED = 'IMPORT_SCORES_DIALOG_UNLOADED'
export const UPDATE_SCORE = 'UPDATE_SCORE'

export const DELETE_TASK = 'DELETE_TASK'
export const LOAD_TASK_SCORES = 'LOAD_TASK_SCORES'
export const TASKS_DIALOG_LOADED = 'TASKS_DIALOG_LOADED'
export const TASKS_DIALOG_UNLOADED = 'TASKS_DIALOG_UNLOADED'
export const UNLOAD_TASK_SCORES = 'UNLOAD_TASK_SCORES'
export const UPDATE_TASK = 'UPDATE_TASK'

export const ACCOUNT_BALANCE_LOADED = 'ACCOUNT_BALANCE_LOADED'
export const ACCOUNT_BALANCE_UNLOADED = 'ACCOUNT_BALANCE_UNLOADED'
export const ACCOUNT_VIEW_LOADED = 'ACCOUNT_VIEW_LOADED'
export const ACCOUNT_VIEW_UNLOADED = 'ACCOUNT_VIEW_UNLOADED'
export const ACCOUNTS_PAGE_UNLOADED = 'ACCOUNTS_PAGE_UNLOADED'
export const ADD_TRANSACTION = 'ADD_TRANSACTION'
export const ADD_TRANSACTIONS = 'ADD_TRANSACTIONS'
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION'
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION'
export const TRANSACTION_DIALOG_UNLOADED = 'TRANSACTION_DIALOG_UNLOADED'
export const TRANSACTIONS_DIALOG_UNLOADED = 'TRANSACTIONS_DIALOG_UNLOADED'

export const STUDENT_SEARCH_LOADED = 'STUDENT_SEARCH_LOADED'
export const STUDENT_SEARCH_UNLOADED = 'STUDENT_SEARCH_UNLOADED'

export const PRODUCTS_PAGE_LOADED = 'PRODUCTS_PAGE_LOADED'
export const PRODUCTS_PAGE_UNLOADED = 'PRODUCTS_PAGE_UNLOADED'
export const PRODUCT_DIALOG_UNLOADED = 'PRODUCT_DIALOG_UNLOADED'
export const PRODUCT_SELECTOR_LOADED = 'PRODUCT_SELECTOR_LOADED'
export const PRODUCT_SELECTOR_UNLOADED = 'PRODUCT_SELECTOR_UNLOADED'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'

export const WHATS_NEW_DIALOG_LOADED = 'WHATS_NEW_DIALOG_LOADED'
export const WHATS_NEW_DIALOG_UNLOADED = 'WHATS_NEW_DIALOG_UNLOADED'
export const WHATS_NEW_FLAGS_RESET = 'WHATS_NEW_FLAGS_RESET'

export const NOTIFICATION_DIALOG_LOADED = 'NOTIFICATION_DIALOG_LOADED'
export const NOTIFICATION_DIALOG_UNLOADED = 'NOTIFICATION_DIALOG_UNLOADED'

export const ECONOMY_DIALOG_LOADED = 'ECONOMY_DIALOG_LOADED'
export const ECONOMY_DIALOG_UNLOADED = 'ECONOMY_DIALOG_UNLOADED'

export const ADD_PURCHASE = 'ADD_PURCHASE'
export const ADD_PURCHASE_DIALOG_UNLOADED = 'ADD_PURCHASE_DIALOG_UNLOADED'
export const ADD_TO_CART = 'ADD_TO_CART'
export const DELETE_CART_PRODUCT = 'DELETE_CART_PRODUCT'
export const DELETE_PURCHASE = 'DELETE_PURCHASE'
export const PRIME_PURCHASE_DIALOG_UNLOADED = 'PRIME_PURCHASE_DIALOG_UNLOADED'
export const PURCHASES_DIALOG_UNLOADED = 'PURCHASES_DIALOG_UNLOADED'
export const STUDENT_CART_LOADED = 'STUDENT_CART_LOADED'
export const STUDENT_CART_UNLOADED = 'STUDENT_CART_UNLOADED'
export const STUDENT_DELETE_PURCHASE = 'STUDENT_DELETE_PURCHASE'
export const STUDENT_PURCHASES_LOADED = 'STUDENT_PURCHASES_LOADED'
export const STUDENT_PURCHASES_UNLOADED = 'STUDENT_PURCHASES_UNLOADED'
export const SUBMIT_PURCHASE = 'SUBMIT_PURCHASE'
export const SUBMIT_PRIME_PURCHASE = 'SUBMIT_PRIME_PURCHASE'
export const TEACHER_PURCHASES_LOADED = 'TEACHER_PURCHASES_LOADED'
export const TEACHER_PURCHASES_UNLOADED = 'TEACHER_PURCHASES_UNLOADED'
export const UPDATE_CART_PRODUCT_QUANTITY = 'UPDATE_CART_PRODUCT_QUANTITY'
export const UPDATE_PURCHASE = 'UPDATE_PURCHASE'

export const ADD_BONUS = 'ADD_BONUS'
export const DELETE_BONUS = 'DELETE_BONUS'
export const LOAD_BONUSES = 'LOAD_BONUSES'
export const UNLOAD_BONUSES = 'UNLOAD_BONUSES'
export const UPDATE_BONUS = 'UPDATE_BONUS'

export const ADD_PASS = 'ADD_PASS'
export const DELETE_PASS = 'DELETE_PASS'
export const GET_LAST_PASS = 'GET_LAST_PASS'
export const LOAD_STUDENT_PASSES = 'LOAD_STUDENT_PASSES'
export const LOAD_TEACHER_PASSES = 'LOAD_TEACHER_PASSES'
export const LOAD_TEACHER_PASS_DIALOG = 'LOAD_TEACHER_PASS_DIALOG'
export const STUDENT_PASSES_LOADED = 'STUDENT_PASSES_LOADED'
export const STUDENT_PASSES_UNLOADED = 'STUDENT_PASSES_UNLOADED'
export const UNLOAD_MANAGE_PASSES = 'UNLOAD_MANAGE_PASSES'
export const UPDATE_PASS = 'UPDATE_PASS'

export const ADD_FINE = 'ADD_FINE'
export const DELETE_FINE = 'DELETE_FINE'
export const LOAD_FINES = 'LOAD_FINES'
export const UNLOAD_FINES = 'UNLOAD_FINES'
export const UPDATE_FINE = 'UPDATE_FINE'
// export const X = 'X'
