import React from 'react'
import { connect } from 'react-redux'
import {
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Visibility } from '@material-ui/icons'

import AddToQueueDialog from './AddToQueueDialog'
import AudioPlayer from '../AudioPlayer'
import SkipTrackDialog from './SkipTrackDialog'

import agent from '../../agent'

import { ADD_TO_QUEUE, SKIP_TRACK } from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      minHeight: 140,
    },
  },
  content: {
    display: 'flex',
  },
  controls: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  image: {
    height: 140,
    minWidth: 140,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const mapStateToProps = (state) => ({
  balance: state.scores.balance?.balance,
  cost: {
    addToQueue: state.common.settings.jukebox.purchaseAmount,
    skipTrack: state.common.settings.jukebox.skipSongs.purchaseAmount,
  },
  currentUser: state.common.currentUser,
  skipEnabled: state.common.settings.jukebox.skipSongs.enabled,
  tokens: state.common.settings.spotifyTokens,
})

const mapDispatchToProps = (dispatch) => ({
  addToQueue: (cost, track, tokens) =>
    dispatch({
      type: ADD_TO_QUEUE,
      payload: agent.Spotify.addToQueue(cost, track, tokens),
      snackbar: { message: 'Song added to queue', variant: 'success' },
    }),
  skipTrack: (cost, track, tokens) =>
    dispatch({
      type: SKIP_TRACK,
      payload: agent.Spotify.skipTrack(cost, track, tokens),
      snackbar: { message: 'Song has been skipped', variant: 'success' },
    }),
})

const TrackCard = ({
  addToQueue,
  balance,
  cost,
  currentUser,
  hideControls,
  isCurrent,
  skipEnabled,
  skipTrack,
  track,
  tokens,
}) => {
  const classes = useStyles()

  const albumArtists = track.album.artists
    .map((artist) => artist.name)
    .join(', ')

  const artists = track.artists.map((artist) => artist.name).join(', ')

  const handleAddToQueue = () => {
    addToQueue(
      cost.addToQueue,
      {
        artists,
        name: track.name,
        uri: track.uri,
      },
      tokens
    )
  }

  const handleSkipTrack = () => {
    skipTrack(
      cost.skipTrack,
      {
        artists,
        name: track.name,
      },
      tokens
    )
  }

  return (
    <Card className={classes.card}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <div className={classes.info}>
            <Typography color='primary' component='h2' variant='h5'>
              {track.name}
            </Typography>
            <Typography color='secondary' variant='subtitle2'>
              {artists}
            </Typography>
          </div>
        </CardContent>
        {!hideControls && (
          <div className={classes.controls}>
            <AddToQueueDialog
              balance={balance}
              cost={cost.addToQueue}
              currentUser={currentUser}
              onSubmit={handleAddToQueue}
            />
            <AudioPlayer src={track.preview_url} />
            <Tooltip title='View song on Spotify' arrow>
              <IconButton href={track.external_urls.spotify} target='_blank'>
                <Visibility />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {isCurrent && skipEnabled && (
          <div className={classes.controls}>
            <SkipTrackDialog
              balance={balance}
              cost={cost.skipTrack}
              currentUser={currentUser}
              onSubmit={handleSkipTrack}
            />
          </div>
        )}
      </div>

      <CardMedia
        className={classes.image}
        image={track.album.images[0].url}
        title={`Album art for "${track.album.name}" by ${albumArtists}`}
      />
    </Card>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackCard)
