import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MaterialTable, { MTableAction } from 'material-table'
import moment from 'moment'

import TaskDataDialog from './TaskDataDialog'
import agent from '../../agent'

import {
  DELETE_TASK,
  TASKS_DIALOG_LOADED,
  UPDATE_TASK,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  tasks: state.tasks.tasks,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (task) =>
    dispatch({
      type: DELETE_TASK,
      payload: agent.Tasks.delete(task),
      snackbar: { message: 'Assignment deleted', variant: 'success' },
    }),
  onLoad: (courseId) =>
    dispatch({
      type: TASKS_DIALOG_LOADED,
      payload: agent.Tasks.getAll(courseId),
    }),
  onUpdate: (task) =>
    dispatch({
      type: UPDATE_TASK,
      payload: agent.Tasks.update(task),
      snackbar: { message: 'Assignment updated', variant: 'success' },
    }),
})

const TasksTable = ({ course, onDelete, onLoad, onUpdate, tasks }) => {
  const [data, setData] = useState([])

  const mappedData = tasks.map((task) => ({
    createdAt: moment(task.createdAt),
    createdAtString: task.createdAt,
    id: task._id,
    multiplier: task.multiplier,
    slug: task.slug,
    title: task.title,
    totalPoints: task.totalPoints,
  }))

  const [columns] = useState([
    {
      title: 'Date',
      field: 'createdAt',
      editable: 'never',
      render: (rowData, renderType) =>
        renderType === 'row'
          ? rowData.createdAt.format('ddd, MMM D, YYYY')
          : rowData.format('ddd, MMM D, YYYY'),
    },
    { title: 'Title', field: 'title', editable: 'never' },
    { title: 'Total Points', field: 'totalPoints' },
    { title: 'Multiplier', field: 'multiplier' },
  ])

  useEffect(() => {
    onLoad(course._id)
  }, [])

  useEffect(() => {
    setData(mappedData)
  }, [tasks])

  return (
    <MaterialTable
      actions={[
        {
          component: 'TaskDataDialog',
          icon: 'assessment',
          position: 'row',
          tooltip: 'View data',
        },
      ]}
      columns={columns}
      components={{
        Action: (props) => {
          if (props.action.component === 'TaskDataDialog')
            return <TaskDataDialog course={course} task={props.data} />
          return <MTableAction {...props} />
        },
      }}
      data={data}
      localization={{
        body: {
          emptyDataSourceMessage: 'No assignments found for this course',
          editRow: {
            deleteText:
              'Delete assignment? This will delete all scores for this assignment.',
            saveTooltip: 'Confirm',
          },
        },
      }}
      options={{ actionsColumnIndex: -1, paging: false, showTitle: false }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data]
            const index = oldData.tableData.id
            dataUpdate[index] = {
              ...newData,
              createdAt: moment(newData.createdAtString),
            }
            setData([...dataUpdate])
            onUpdate(newData)
            resolve()
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const newData = [...data]
            const index = oldData.tableData.id
            newData.splice(index, 1)
            setData([...newData])
            onDelete(oldData)
            resolve()
          }),
      }}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksTable)
