import React, { useEffect, useState } from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { Grid } from '@material-ui/core'

import SortableGroupItem from './SortableGroupItem'

const SortableGroups = ({ groups, numGroups }) => {
  const [items, setItems] = useState([])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over.id)

        return renumberGroups(arrayMove(items, oldIndex, newIndex))
      })
    }
  }

  function mapGroups(groups) {
    return groups.map((group, index) => ({
      id: index + 1,
      members: group,
      number: index + 1,
    }))
  }

  function renumberGroups(groups) {
    return groups.map((group, index) => ({
      ...group,
      number: index + 1,
    }))
  }

  useEffect(() => {
    setItems(mapGroups(groups))
  }, [groups])

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={items}>
        <Grid container spacing={2} item xs={12}>
          {items.map((group) => (
            <SortableGroupItem
              key={group.id}
              id={group.id}
              members={group.members}
              number={group.number}
              numGroups={numGroups}
            />
          ))}
        </Grid>
      </SortableContext>
    </DndContext>
  )
}

export default SortableGroups
