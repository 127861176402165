import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: '0 auto',
    marginBottom: theme.spacing(2),
  },
  card: { height: '100%' },
}))

const SortableGroupItem = ({ id, members, number, numGroups }) => {
  const classes = useStyles()

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id })

  const style = {
    cursor: isDragging ? 'grabbing' : 'grab',
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Grid
      item
      xs={numGroups > 2 ? 4 : 6}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <Card className={classes.card} variant='outlined'>
        <CardContent>
          <Avatar className={classes.avatar}>{number}</Avatar>
          {members.map((member) => (
            <Typography align='center' variant='h5' key={member._id}>
              {member.firstName} {member.lastName.charAt(0)}
            </Typography>
          ))}
        </CardContent>
      </Card>
    </Grid>
  )
}

export default SortableGroupItem
