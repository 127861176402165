import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Container,
  Dialog,
  Fab,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify } from '@fortawesome/free-brands-svg-icons'

import DialogAppBar from '../DialogAppBar'
import ListErrors from '../ListErrors'
import PageHeader from '../PageHeader'
import PageWrapper from '../PageWrapper'
import TrackCard from './TrackCard'
import { SlideUpTransition } from '../../transitions'

import agent from '../../agent'

import { GET_QUEUE } from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const mapStateToProps = (state) => ({
  ...state.jukebox,
  tokens: state.common.settings.spotifyTokens,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: (tokens) =>
    dispatch({ type: GET_QUEUE, payload: agent.Spotify.getQueue(tokens) }),
})

const QueueDialog = ({ errors, onLoad, queue, tokens }) => {
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const isPlaying = queue.currently_playing !== null
  const nowPlaying = queue.currently_playing
  const upNext = queue.queue || []

  const handleClickOpen = () => {
    setOpen(true)
    onLoad(tokens)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    onLoad(tokens)
  }, [])

  return (
    <>
      {isPlaying && (
        <Fab
          className={classes.button}
          color='primary'
          onClick={handleClickOpen}
          variant='extended'
        >
          <FontAwesomeIcon
            className={classes.icon}
            icon={faSpotify}
            size='xl'
          />
          View Queue
        </Fab>
      )}

      <Dialog
        fullScreen
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
        TransitionComponent={SlideUpTransition}
      >
        <DialogAppBar onClose={handleClose} title='Jukebox' />
        <PageWrapper>
          <Container>
            <PageHeader title='Spotify Queue' beta />

            <Grid container spacing={6}>
              {errors && (
                <Grid item xs={12}>
                  <ListErrors errors={errors} />
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography color='secondary' variant='h5' gutterBottom>
                  <em>See what's playing now</em>
                </Typography>
                <Typography variant='body1' gutterBottom>
                  Below you will find the currently playing song and the songs
                  that will play next. If enabled by your teacher, you can also
                  pay a fee to skip the currently playing song.
                </Typography>
                <Typography variant='body2'>
                  <em>
                    Note that information on this page does not update
                    automatically (except when paying to skip a song) so you
                    will need to close this window and reopen it to get the
                    latest information.
                  </em>
                </Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography color='primary' variant='h5' gutterBottom>
                  Now Playing
                </Typography>
                <TrackCard track={nowPlaying} hideControls isCurrent />
              </Grid>

              <Grid item xs={12} md={8}>
                <Typography color='primary' variant='h5' gutterBottom>
                  Up Next
                </Typography>
                {upNext.length > 0 ? (
                  <Grid container item xs={12} spacing={2}>
                    {upNext.map((track) => (
                      <Grid item xs={12} key={track.id}>
                        <TrackCard track={track} hideControls />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant='body1'>No upcoming songs</Typography>
                )}
              </Grid>
            </Grid>
          </Container>
        </PageWrapper>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueDialog)
