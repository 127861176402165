import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { SkipNext } from '@material-ui/icons'

const SkipTrackDialog = ({ balance, cost, currentUser, onSubmit }) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    onSubmit()
    setOpen(false)
  }

  return (
    <>
      <Tooltip title='Skip this song' arrow>
        <IconButton onClick={handleClickOpen}>
          <SkipNext />
        </IconButton>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth='md'
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <DialogTitle>Skip this Song</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to skip this song for a fee of {cost} pts?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {balance >= cost || currentUser.role !== 'student' ? (
            <Button
              autoFocus
              color='primary'
              onClick={handleSubmit}
              variant='contained'
            >
              Purchase
            </Button>
          ) : (
            <Tooltip
              title={`Insufficient funds! You have ${balance} pts`}
              arrow
            >
              <span>
                <Button color='primary' disabled variant='contained'>
                  Puchase
                </Button>
              </span>
            </Tooltip>
          )}
          <Button onClick={handleClose} variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SkipTrackDialog
